.contacts_block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 20px;
    @include media-breakpoint-up(md) {
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 40px;
    }
    &__item {
        flex-basis: 33%;
        align-self: center;
        margin-bottom: 15px;
        text-align: center;
        @include media-breakpoint-up(md) {
            margin-bottom: 0;
        }
    }
    .address {
        &__text {
            font-size: 20px;
            line-height: 1.2;
            color: $brand-third;
        }
    }
    .email {
        display: flex;
        justify-content: center;
        .icon--envelope {
            width: 35px;
            height: 35px;
            fill: $brand-third
        }
        &__link {
            font-weight: 500;
            font-size: 20px;
            color: $brand-third;
        }
    }
}