.page_caption {
    margin-top: 15px;
    font-size: 26px;
    text-align: center;
    @include media-breakpoint-up(md) {
        font-size: 40px;
    }
}

.page_sub_caption {
    font-size: 24px;
    @include media-breakpoint-up(md) {
        font-size: 30px;
    }
}