$notification--error_color: #721c24;
$notification--error_bg: #f8d7da;
$notification--success_color: #004c00;
$notification--success_bg: #ccffcc;

.notification {
    display: none;
    width: 250px;
    margin-bottom: 5px;
    padding: 1px 5px;
    text-align: center;
    border-radius: 3px;
    &--error {
        color: $notification--error_color;
        background-color: $notification--error_bg;
    }
    &--success {
        color: $notification--success_color;
        background-color: $notification--success_bg;
    }
}