$banner_bg: #ceeefb;

.banner {
    background-color: $banner_bg;
    &__content {
        display: flex;
        flex-direction: column;
        text-align: center;
        width: 100%;
        min-height: 200px;
        position: relative;
        padding: 35px 10px;
        background: url('../img/banner_background.png') center;
        background-size: auto 100%;
        @include media-breakpoint-up(md) {
            height: auto;
            min-height: 500px;
            margin-top: 0;
            padding: 10px;
            background: none;
        }
    }
    &__caption {
        margin: auto;
        text-align: center;
        font-size: 30px;
        @include media-breakpoint-up(md) {
            margin: 100px auto;
            font-size: 32px;
        }
        @include media-breakpoint-up(lg) {
            font-size: 40px;
        }
        &--small {
            font-size: 26px;
            font-weight: 500;
            @include media-breakpoint-up(md) {
                font-size: 27px;
            }
            @include media-breakpoint-up(lg) {
                font-size: 36px;
            }
        }
    }
    .parallax-scene {
        position: relative;
        overflow: hidden;
        .bottom_layer {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background: url('../img/bottom_layer.png') top center no-repeat;
        }
        .ground_layer {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 89px;
            background: url('../img/ground_layer.png') bottom center repeat-x;
        }
        .buildings2_layer {
            position: absolute !important;
            top: 0;
            bottom: 85px;
            width: 2200px;
            background: url('../img/buildings2_layer.png') bottom center no-repeat;
            left: 50% !important;
            margin-left: -1100px;
        }
        .buildings_layer {
            position: absolute;
            top: 0;
            bottom: 85px;
            width: 2200px;
            left: 50% !important;
            margin-left: -1100px;
            background: url('../img/buildings_layer.png') bottom center no-repeat;
        }
        .noise_layer {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background: url('../img/noise_layer.png') center center repeat;
            opacity: .4;
        }
        .clouds_layer {
            position: absolute;
            height: 78px;
            left: 0;
            width: 100%;
            background: url('../img/clouds_layer.png') center bottom repeat-x;
        }
        .gadget_layer {
            display: none!important;
            position: absolute;
            top: 165px!important;
            width: 340px;
            height: 290px;
            left: 50% !important;
            margin-left: -510px;
            opacity: 1;
            right: 0;
            background: url('../img/gadget_layer.png') bottom left no-repeat;
            background-size: 100%;
            transition: margin-left .7s, opacity .7s;
            @include media-breakpoint-up(md) {
                display: block!important;
            }
            @include media-breakpoint-up(lg) {
                top: -5px !important;
                width: 465px;
                height: 464px;
                margin-left: -820px;
            }
        }
        .gadget_layer.start {
            margin-left: -1020px;
            opacity: 0;
        }
        .notebook_layer {
            display: none!important;
            position: absolute;
            top: 200px!important;
            height: 250px;
            left: 50% !important;
            margin-left: 100px;
            opacity: 1;
            width: 450px;
            background: url('../img/notebook_layer.png') bottom right no-repeat;
            background-size: 100%;
            transition: margin-left .7s, opacity .7s;
            @include media-breakpoint-up(md) {
                display: block!important;
            }
            @include media-breakpoint-up(lg) {
                top: 93px !important;
                margin-left: 330px;
                width: 650px;
                height: 359px;
            }
        }
        .notebook_layer.start {
            margin-left: 530px;
            opacity: 0;
        }
    }
    &--medium {
        .banner__content {
            @include media-breakpoint-up(md) {
                min-height: 320px;
            }
        }
    }
    &--small {
        .banner__content {
            @include media-breakpoint-up(md) {
                min-height: 200px;
            }
        }
        .banner__caption {
            margin-bottom: 20px;
            @include media-breakpoint-up(md) {
                margin: auto;
            }
        }
        .banner__text {
            margin-bottom: auto;
            font-weight: 300;
            font-size: 18px;
            padding: 15px 1px;
        }
    }
}
