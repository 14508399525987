$menu_mobile_overlay_bg: rgba(0, 0, 0, .7);

.menu_mobile {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: -250px;
    width: 250px;
    height: 100%;
    overflow-x: auto;
    transition: 0.4s;
    z-index: $zindex-menu_mobile;
    &__content {
        height: 100%;
        margin: 0;
        padding: 0 20px;
        background-color: $body-background;
        z-index: $zindex-menu_mobile;
    }
    &__close {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 5px 0;
        border-bottom: 1px solid $border-primary-color;
    }
    &__btn {
        position: relative;
        width: 50px;
        height: 50px;
        margin: 15px 0 10px -9px;
        padding: 0;
        font-size: 0;
        background: 0 0;
        border: none;
        &::before {
            content: "";
            position: absolute;
            top: 50%;
            right: 0;
            left: 0;
            margin: -1px auto auto;
            width: 30px;
            height: 3px;
            background-color: $brand-primary;
            transform: rotate(45deg);
        }
        &::after {
            content: "";
            position: absolute;
            top: 50%;
            right: 0;
            left: 0;
            margin: -1px auto auto;
            width: 30px;
            height: 3px;
            background-color: $brand-primary;
            transform: rotate(-45deg);
        }
        &:focus {
            outline: none;
        }
    }
    &__list {
        margin: 0;
        padding: 10px 0;
        list-style: none;
        border-bottom: 1px solid $border-primary-color;
    }
    &__item {
        margin-bottom: 10px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    &__link {
        font-size: 1.2rem;
        font-weight: 600;
        color: $brand-primary;
        white-space: nowrap;
        &:hover,
        &:focus {
            color: $brand-third;
        }
        &--active {
            color: $brand-third;
        }
    }
    &::after {
        content: '';
        position: fixed;
        left: 0;
        top: 0;
        bottom: 0;
        right: 100%;
        background-color: rgba(0,0,0,.35);
        transition: all .4s ease;
    }
    &--open::after {
        left: 250px;
        right: 0;
    }
}