.advantage_block {
    text-align: center;
    @include media-breakpoint-up(md) {
        text-align: left;
    }
    &:last-child {
        margin-right: 0;
    }
    .icon {
        width: 60px;
        height: 60px;
        fill: $brand-third;
    }
    &__caption {
        margin-top: 0;
        margin-bottom: 15px;
        color: $brand-primary;
    }
}