.example_block {
    flex-direction: column-reverse;
    margin-bottom: 40px;
    @include media-breakpoint-up(md) {
        flex-direction: row;
    }
    &:first-child {
        margin-top: 40px;
    }
    &__image {
        display: block;
        img {
            width: 100%;
            height: auto;
        }
    }
    &__text_wrap {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    &__header {
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
    }
    &__caption {
        margin-bottom: 0;
        text-align: left;
        font-weight: 500;
        font-size: 30px;
        line-height: 1;
        color: $brand-secondary;
        @include media-breakpoint-up(md) {
            text-align: left;
        }
    }
    &__description {
        font-size: 16px;
    }
}
a.example_block__caption {
    &:hover,
    &:focus {
        color: $brand-third;
    }
}
