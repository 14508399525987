@import "_variables";
@import "../../_helpers/variables";

// ================================
// Global style
// ================================

a,
.link {
	color: $typography_link__color;
	outline: $typography_link__outline;
	text-decoration: $typography_link__decoration;
	&:hover {
		color: $typography_link__hover-color;
		text-decoration: $typography_link__hover-decoration;
	}
	&:focus {
		color: $typography_link__focus-color;
		text-decoration: $typography_link__hover-decoration;
	}
	&:active {
		color: $typography_link__active-color;
		text-decoration: $typography_link__hover-decoration;
	}
}

// ================================
// Responsive YouTube video
// ================================
.video-container {
	position: relative;
	padding-bottom: 56.25%;
	padding-top: 30px;
	height: 0;
	overflow: hidden;
	iframe,
	object,
	embed {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

// ================================
// Typography style
// ================================
.ckeditor,
.cke_editable,
.typography {
	font-family: $typography_font__family;
	font-size: $typography_font__size;
	line-height: $typography_line_height;
	color: $typography_text_color;

	//Global margin
	p,
	ul,
	hr,
	dl,
	pre,
	address,
	blockquote,
	table {
		margin: #{$typography_line_height}em 0;
	}

	h1 {
		color: $brand-secondary;
	}

	//Headers
	@each $header_type, $typography_headers__font-size in $typography_headers {
		#{$header_type} {
			margin: $typography_headers__margin-top 0 $typography_headers__margin-bottom 0;
			color: $typography_headers__color;
			font-family: $typography_headers__family;
			font-size: #{$typography_headers__font-size};
		}
	}

	//Else :)
	b,
	strong {
		color: $typography_bold_strong__color;
		font-weight: bold;
	}

	i, cite, em, var, address, dfn, caption {
		font-style: italic;
	}

	small {
		font-size: 70%;
		font-weight: 400;
	}

	ul {
		list-style: none !important;
		list-style-position: inside;
		padding: 0;
		li {
			overflow: hidden;
			position: relative;
			margin: 5px 0;
			padding-left: 30px;
			&:before {
				content: "";
				display: block;
				margin: 0;
				position: absolute;
				top: 8px;
				left: 6px;
				width: 7px;
				height: 7px;
				border-radius: 50%;
				background: $typography_ul_li__bg;
			}
			ul li {
				&:before {
					top: 10px;
					left: 12px;
					width: 8px;
					height: 1px;
				}
			}
		}
	}

	ol {
		list-style: none inside;
		counter-reset: item;
		padding: 0;
		li {
			overflow: hidden;
			margin: 5px 0;
			padding-left: 35px;
			&:before {
				content: counters(item, ".") " ";
				counter-increment: item;
				position: relative;
				display: inline-block;
				min-width: 25px;
				margin-left: -35px;
				margin-right: 10px;
				padding: 2px 3px 1px 3px;
				font-size: 80%;
				font-weight: 700;
				text-align: center;
				color: $typography_ol_li__color;
				border-radius: 100px;
				border: 2px solid $typography_ol_li__border;
				box-sizing: border-box;
			}
		}
	}

	img {
		max-width: 100%;
		max-height: 100%;
	}

	u,
	ins {
		text-decoration: none;
		border-bottom: 2px solid $typography_bold_strong__border-color;
	}

	table {
		display: block;
		max-width: 100% !important;
		overflow-x: auto;
		background-color: transparent;
		border-collapse: collapse;
		border-spacing: 0;
		border: none;

		&.table-null,
		&.table-null th,
		&.table-null td {
			border-color: transparent;
		}

		&.table-null th {
			background: transparent;
		}

		&.table-zebra,
		&.table-zebra th,
		&.table-zebra td {
			border-color: transparent;
		}

		&.table-zebra tr:nth-child(even) {
			background-color: $typography_tr-zebra_bg;
		}

		ol li,
		ul li { //Для нормального обтекание списков внутри таблицы
			display: list-item;
		}
	}

	tbody, tfoot, thead,
	tr, th, td {
		border: inherit;
	}

	th, td {
		padding: $typography_th_td_padding;
		text-align: left;
		vertical-align: top;
		border: 1px solid $typography_table__border-color;
	}

	th {
		text-align: center;
		background-color: $typography_th_color;
	}
	dd + dt {
		margin: #{$typography_line_height}em 0 0;
	}

	dd {
		margin-left: $typography_font__size * 2;
	}

	dt, th {
		font-weight: bold;
	}

	pre {
		tab-size: 4;
		padding: $typography_pre__padding;
		background-color: $typography_pre__bg-color;
	}

	code, kbd, samp {
		padding: 0 3px;
	}

	code {
		background-color: $typography_code__bg-color;
		color: $typography_code__color;
	}

	kbd {
		color: $typography_kbd__color;
		background-color: $typography_kbd__bg-color;
	}

	samp {
		background-color: $typography_samp__bg-color;
	}

	mark {
		padding: $typography_mark__padding;
		color: $typography_mark__color;
		background-color: $typography_mark__bg-color;
	}

	q {
		&:before {
			content: open-quote;
		}
		&:after {
			content: close-quote;
		}
	}
	blockquote {
		border-radius: $typography_blockquote__border-radius;
		border: 1px dashed $typography_blockquote__border-color;
		padding: $typography_blockquote__padding;
	}
	hr {
		clear: both;
		margin: 50px 0;
		border: 0;
		border-top: 1px solid $typography_hr__color;
	}
}
