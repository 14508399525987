.delivery_block {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-bottom: 5px;
    @include media-breakpoint-up(md) {
        justify-content: space-between;
        margin-bottom: 15px;
    }
    &__link {
        display: block;
        margin: 0 15px 10px 15px;
        @include media-breakpoint-up(lg) {
            margin: 0;
        }
    }
}