$tab_content_bg: #e0f3ff;

.tab_content {
    &__caption {
        margin-bottom: 15px;
        text-align: center;
        font-size: 26px;
        @include media-breakpoint-up(md) {
            text-align: left;
            font-size: 32px;
        }
    }
    &__text {
        font-size: 19px;
        @include media-breakpoint-up(md) {
            font-size: 21px;
        }
    }
}

.tab-content--faq {
    .tab-content__block {
        margin-bottom: 15px;
        padding: 10px;
        background-color: $tab_content_bg;
        border: 1px solid $tab_content_bg;
    }
    .tab-content__caption {
        font-size: 18px;
        line-height: 1.2;
        color: $brand-primary;
    }
}