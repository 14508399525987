// 3 вида(при ховере): ссылка в одну строку(подчеркивание border), не на элементе <a>(нет подчеркивание), многострочная ссылка(нужен <span> внутри <a>. Подчеркивание linear-gradient)
.decoration_link {
      display: inline;
      color: $brand-third;
      padding-bottom: 1px;
      &:hover,
      &:focus {
            color: $brand-third;
            border-bottom: none;
      }
}
a.decoration_link {
      &:hover,
      &:focus {
            border-bottom: 1px solid $brand-third;
            padding-bottom: 0;
      }
      &--multirow {
            &:hover,
            &:focus {
                  border-bottom: none;
                  padding-bottom: 1px;
                  .decoration_link__inner {
                        background-image: linear-gradient(to bottom, $brand-third 0%, $brand-third);
                        background-position: 0 1.3em;
                        background-repeat: repeat-x;
                        background-size: 1px 1px;
                  }
            }
      }
}
