.examples {
      &__content {
            margin-top: 40px;
      }
      &__subtitle {
            display: block;
            font-size: 30px;
            font-weight: 500;
            color: #235983;
            line-height: 1;
      }
}
