.contacts {
    .icon--phone {
        display: block;
        width: 40px;
        height: 40px;
        fill: $brand-third;
        cursor: pointer;
        @include media-breakpoint-up(lg) {
            display: none;
        }
    }
    .contacts__wrap {
        display: none;
        flex-direction: column;
        align-items: center;
        position: absolute;
        left: 0;
        bottom: -49px;
        width: 100%;
        height: 50px;
        padding: 5px;
        text-align: center;
        background-color: $body-background;
        border-top: 1px solid $border-primary-color;
        border-bottom: 1px solid $border-primary-color;
        z-index: 1;
        @include media-breakpoint-up(lg) {
            display: flex;
            flex-direction: column;
            align-items: center;
            position: static;
            padding: 0;
            width: auto;
            height: auto;
            border: none;
        }
    }
    &__text {
        margin: 0;
        margin-bottom: 4px;
        font-size: 11px;
        line-height: 1.2;
        font-weight: 400;
    }
    &__text--decoration {
        margin-bottom: 0;
        font-size: 20px;
        line-height: 1;
        font-weight: 700;
        color: $brand-third;
        &:hover,
        &:focus {
            color: $brand-third;
        }
    }
    &--reverse {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        align-self: center;
        @include media-breakpoint-up(md) {
            align-items: flex-start;
        }
        .contacts__text--decoration {
            margin-bottom: 5px;
            font-weight: 500;
            color: $brand-primary;
        }
    }
}