.our_feature {
    margin-bottom: 40px;
    &:first-child {
        margin-top: 40px;
    }
    &__title{
        text-align: center;
    }
    &__text{
        @include media-breakpoint-up(md){
            font-size: 16px;
        }
    }
    &__text_block {
        margin-bottom: 20px;
        text-align: center;
        @include media-breakpoint-up(md) {
            margin-bottom: 0;
            text-align: left;
        }
    }
    &__caption {
        margin-bottom: 15px;
        font-size: 24px;
        line-height: 1.5;
        color: $brand-secondary;
        @include media-breakpoint-up(md) {
            font-size: 30px;
        }
    }
    &__image_block {
        img {
            display: block;
            max-width: 100%;
            margin: 0 auto;
        }
    }
}