.page_header {
    position: relative;
    background-color: $body-background;
    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 1px;
        color: $border-primary-color;
        background-color: $border-primary-color;
    }
    .container {
        @include media-breakpoint-down(sm) {
            max-width: 100%;
        }
    }
    &__item_wrap {
        display: flex;
        align-items: center;
    }
    &__content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-height: 86px;
        .menu_mobile__opener {
            @include media-breakpoint-up(md) {
                display: none;
            }
            .icon_button {
                border: none;
                background: none;
                outline: none;
                cursor: pointer;
                .icon--bars {
                    width: 30px;
                    height: 30px;
                    vertical-align: middle;
                    fill: $brand-primary;
                }
            }
        }
        .contacts {
            @include media-breakpoint-up(md) {
                margin-right: 20px;
            }
            @include media-breakpoint-up(lg) {
                margin-right: 30px;
            }
        }
    }
}