$page_footer_color: #cfd6df;
$page_footer_bg: $brand-secondary;
$page_footer_link_color: #fff;
$about_company_image_bg: #323343;
$about_company_image_bg--hover: #ff4213;

.page_footer {
    padding: 20px 0;
    margin-top: 20px;
    font-size: 14px;
    font-weight: 400;
    color: $page_footer_color;
    background-color: $page_footer_bg;
    @include media-breakpoint-up(md){
        margin-top: 40px;
    }
    &__list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: 0;
        margin-bottom: 20px;
        padding: 0;
        list-style: none;
        @include media-breakpoint-up(md) {
            justify-content: space-between;
            margin-bottom: 0;
        }
    }
    &__item {
        margin-right: 15px;
        &:last-child {
            margin-right: 0;
        }
    }
    &__link {
        font-size: 18px;
        line-height: 1.5;
        font-weight: 500;
        color: $page_footer_link_color;
        &:hover,
        &:focus {
            color: $page_footer_link_color;
            border-bottom: 1px solid $page_footer_link_color;
        }
    }
    &__block {
        .page_footer__text {
            font-size: 14px;
        }
    }
    .about_company {
        text-align: center;
        @include media-breakpoint-up(md) {
            text-align: left;
        }
        &__image {
            background-color: $about_company_image_bg;
            transition: all .15s ease-out;
            &:hover {
                background-color: $about_company_image_bg--hover;
                transition: all .15s ease-out;
            }
        }
    }
}