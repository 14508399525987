$tr_hover_bg: #e0f3ff;
$decorative_element__text_color: #fff;
$decorative_element--first_color1: #c76f05;
$decorative_element--first_color2: #ffa125;
$decorative_element--second_color1: #0f4975;
$decorative_element--second_color2: #5da5dc;
$decorative_element--third_color1: #0f4975;
$decorative_element--third_color2: #5da5dc;

.responsive_table--md {
    @include media-breakpoint-up(md) {
        border: none;
        thead {
            display: table-header-group;
            vertical-align: bottom;
            .responsive_table__title {
                text-align: left;
                font-weight: 500;
            }
        }
        tbody {
            tr {
                &:hover {
                    background-color: $tr_hover_bg;
                }
                &:last-child{
                    &:hover{
                        background-color: transparent;
                    }
                }
            }
        }
        tr {
            td {
                display: table-cell;
                padding: 15px 10px;
                text-align: center;
                border-bottom: none;
                &:first-child {
                    color: $brand-primary;
                    background-color: transparent;
                }
            }
        }
        .responsive_table__title {
            text-align: left;
            font-weight: 300;
            color: $brand-primary;
        }
        .responsive_table__text {
            text-align: center;
            font-size: 20px;
            line-height: 1;
            &--small {
                font-size: 13px;
            }
        }
        .responsive_table__mobile_wrap {
            &:after {
                content: none;
            }
        }
        .decorative_element {
            display: flex;
            width: 150px;
            height: 150px;
            margin: 0 auto;
            border-radius: 50%;
            @include media-breakpoint-up(lg) {
                width: 210px;
                height: 210px;
            }
            &--first {
                background: linear-gradient(0deg, $decorative_element--first_color1, $decorative_element--first_color2);
            }
            &--second {
                background: linear-gradient(0deg, $decorative_element--second_color1, $decorative_element--second_color2);
            }
            &--third {
                background: linear-gradient(0deg, $decorative_element--third_color1, $decorative_element--third_color2);
            }
            &__text {
                margin: auto;
                font-size: 14px;
                font-weight: 400;
                color: $decorative_element__text_color;
                text-shadow: 0 2px 2px (lighten($brand-primary, 30%));
                @include media-breakpoint-up(lg) {
                    font-size: 18px;
                }
                &--big {
                    font-size: 18px;
                    font-weight: 600;
                    @include media-breakpoint-up(lg) {
                        font-size: 26px;
                    }
                }
            }
        }
    }
}