.menu_desktop {
    display: none;
    margin: 0 15px;
    padding: 0;
    list-style: none;
    @include media-breakpoint-up(md) {
        display: flex;
        flex-wrap: wrap;
        &__item {
            margin-right: 15px;
            @include media-breakpoint-up(lg) {
                margin-right: 30px;
            }
            &:last-child {
                margin-right: 0;
            }
        }
        &__link {
            font-weight: 600;
            color: $brand-primary;
            &:hover,
            &:focus {
                color: $brand-third;
                border-bottom: 1px solid $brand-third;
            }
        }
        &__link--active {
            color: $brand-third;
        }
    }
    @include media-breakpoint-up(lg) {
        margin: 0 25px;
    }
}