@import "../_helpers/variables";
@import "../../node_modules/bootstrap/scss/bootstrap";
@import "../../node_modules/lightgallery/src/sass/lightgallery.scss";
@import "../_helpers/fonts/lato/style";
@import "typography/typography";


//Pages
@import "examples/examples.scss";

//Project blocks
@import "../blocks/page_wrapper/page_wrapper";
@import "../blocks/page_header/page_header";
@import "../blocks/page_footer/page_footer";
@import "../blocks/button/button";
@import "../blocks/icon/icon";
@import "../blocks/logo/logo";
@import "../blocks/contacts/contacts";
@import "../blocks/menu_mobile/menu_mobile";
@import "../blocks/banner/banner";
@import "../blocks/subscription_form/subscription_form";
@import "../blocks/decoration_link/decoration_link";
@import "../blocks/lang/lang";
@import "../blocks/page_caption/page_caption";
@import "../blocks/page_text/page_text";
@import "../blocks/notification/notification";
@import "../blocks/social_links/social_links";
@import "../blocks/menu_desktop/menu_desktop";
@import "../blocks/example_block/example_block";
@import "../blocks/our_feature/our_feature";
@import "../blocks/vertical_tabs/vertical_tabs";
@import "../blocks/tab-content/tab-content";
@import "../blocks/responsive_table/responsive_table";
@import "../blocks/responsive_table--md/responsive_table--md";
@import "../blocks/slider/slider";
@import "../blocks/advantage_block/advantage_block";
@import "../blocks/person_block/person_block";
@import "../blocks/contacts_block/contacts_block";
@import "../blocks/animation-scene/animation-scene";
@import "../blocks/our_feature/our_feature";
@import "../blocks/delivery_block/delivery_block";
@import "../blocks/questionnaire/questionnaire";
@import "../blocks/benefits/benefits";
@import "../blocks/feedback/feedback";
@import "../blocks/clients_filter/clients_filter";
@import "../blocks/float_on_scroll/float_on_scroll";
@import "../blocks/input_radio/input_radio";

//Main styles
body {
    min-width: 320px;
    font-family: $font-family-base;
    font-weight: 300;
    background-color: $body-background;
}

a {
    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
    }
}

h1, h2, h3, h4, h5, h6 {
    font-weight: 500;
    color: $brand-secondary;
    text-transform: none;
    margin-bottom: 1em;
}

h1 {
    font-weight: 700;
}


.image_wrap {
    margin: 40px 0;
    text-align: center;
    img {
        width: 80%;
        height: auto;
    }
}
