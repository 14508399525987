$icon--cross_fill: #b2c1ca;
$td_first-child_bg: $border-primary-color;

.responsive_table {
    width: 100%;
    font-size: 16px;
    color: $brand-primary;
    border: 1px solid $border-primary-color;
    thead {
        display: none;
    }
    tr {
        td {
            display: flex;
            justify-content: space-between;
            padding: 15px 10px;
            border-bottom: 1px solid $border-primary-color;
            &:first-child {
                background-color: $td_first-child_bg;
            }
        }
    }
    p {
        margin-bottom: 0;
    }
    &__mobile_wrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        &:after {
            content: '';
            position: absolute;
            left: 50%;
            width: 2px;
            height: 80%;
            background-color: $border-primary-color;
            transform: translateX(-50%);
        }
        @include media-breakpoint-up(md) {
            display: table-cell;
        }
        .responsive_table__mobile_text {
            text-align: left;
            font-size: 13px;
            @include media-breakpoint-up(md) {
                display: none;
            }
            span {
                font-size: 14px;
            }
        }
    }
    &__title {
        margin: 0 auto;
        font-weight: 500;
        color: $brand-secondary;
    }
    &__text {
        text-align: right;
        &--small {
            font-size: 13px;
        }
    }
    .icon--check {
        width: 30px;
        height: 30px;
        margin-top: 5px;
        fill: $brand-third;
    }
    .icon--cross {
        width: 30px;
        height: 30px;
        margin-top: 5px;
        fill: $icon--cross_fill;
    }
}
