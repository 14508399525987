.benefits{
    margin-top: 40px;
    &__title{
        cursor: pointer;
        &:hover{
            opacity: 0.9;
        }
    }
    &__inner{
        text-align: center;
    }
    &__text{
        font-size: 16px;
        max-width: 570px;
        margin: auto;
    }
    &__items{
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        flex-wrap: wrap;
        margin-top: 30px;
        .benefits__item{
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 170px;
            text-align: center;
            padding-bottom: 20px;
            @include media-breakpoint-up(md){
                width: unset;
            }
            &_icon{
                img{
                    width: 50px;
                    height: 50px;
                    @include media-breakpoint-up(md){
                        width: 70px;
                        height: 70px;
                    }
                }
            }
            &_text{
                font-size: 14px;
                font-weight: bold;
                padding-top: 15px;
                @include media-breakpoint-up(md){
                    font-size: 16px;
                }
            }
        }
    }
    .consultation_button{
        margin: 25px auto;
        width: 100%;
        max-width: 300px;
        display: block;
        @include media-breakpoint-up(md){
            width: auto;
            max-width: unset;
        }
        border-radius: 6px;
        .button__text{
            font-size: 18px;
            margin: 0;
            font-weight: 500;
            @include media-breakpoint-up(md){
                font-size: 22px;
            }
        }
    }
}