.logo {
    display: block;
    width: 36px;
    overflow: hidden;
    @include media-breakpoint-up(md) {
        width: 137px;
        overflow: visible;
    }
    &__image {
        width: 137px;
        height: 38px;
    }
}