.clients_filter {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      @include media-breakpoint-up(md) {
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            margin: 0;
            padding: 30px 0 15px;
      }
      &__desktop {
            display: none;
            @include media-breakpoint-up(md) {
                  display: block;
                  width: 100%;
            }
      }
      &__mobile {
            display: block;
            width: 100%;
            @include media-breakpoint-up(md) {
                  display: none;
            }
            &_content {
                  display: grid;
                  grid-template-columns: 1fr 1fr 38px;
                  grid-gap: 8px;
                  padding: 7px 0;
            }
      }
      &__section {
            display: flex;
            flex-direction: column;
            padding-top: 10px;
            overflow-wrap: anywhere;
            &_title {
                  display: block;
                  font-size: 16px;
                  font-weight: bold;
            }
      }
      &__input {
            margin: 7px 0;
      }
      &__button {
            height: fit-content;
            margin: 5px 0;
            color: #fff;
            background-color: #0e76bc;
            border-bottom: 1px solid #0a588d;
      }
      &__button_reset {
            height: 38px;
            margin-top: auto;
      }
      &__link_block {
            display: block;
            width: fit-content;
            margin: 5px 0;
            font-weight: 600;
            color: $brand-primary;
            &:hover,
            &:focus {
                  color: $brand-third;
                  border-bottom: 1px solid $brand-third;
            }
      }
}
@import '../sidebar';
