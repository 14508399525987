.person_block {
    display: flex;
    margin-bottom: 20px;
    &__wrap {
        align-self: center;
    }
    &__image {
        width: 70px;
        height: 70px;
        margin-right: 10px;
        img {
            width: 100%;
            height: auto;
            border-radius: 50%;
        }
    }
    &__name {
        margin: 0;
        font-weight: 500;
    }
    &__position {
        margin: 0;
    }
}