$analytics_lamp_bg: #808080;
$analytics_lamp_active_bg: #ff0000;

.animation-scene {
    position: relative;
    background-size: 100%;
    margin: 0 auto;
    border: 1px solid transparent;
    z-index: 1;
}

.animation-scene.catalog {
    width: 290px;
    height: 275px;
    background-image: url('../img/keyboard.png');
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: 100%;
    @include media-breakpoint-up(md) {
        width: 100%;
        height: 280px;
    }
    @include media-breakpoint-up(lg) {
        width: 345px;
    }
    .canvas {
        width: 100%;
        margin-top: -100px;
        height: 273px;
        overflow: hidden;
        position: relative;
        bottom: -12px;
        @include media-breakpoint-up(md) {
            bottom: 0;
        }
    }
    .product {
        position: absolute;
        width: 50px;
        height: 50px
    }
    .elem1 {
        left: 85px;
        top: 120px;
        width: 125px;
        height: 130px;
        background-image: url('../img/slide1.png');
        background-size: 100%;
        @include media-breakpoint-up(md) {
            left: 105px;
            top: 100px;
            width: 134px;
            height: 140px;
        }
        @include media-breakpoint-up(lg) {
            left: 109px;
        }
    }
    .elem2 {
        top: 130px;
        right: 26px;
        width: 105px;
        height: 110px;
        background-image: url('../img/slide3.png');
        background-size: 100%;
        @include media-breakpoint-up(md) {
            right: 26px;
            top: 112px;
            height: 118px;
            width: 112px;
        }
    }
    .elem3 {
        top: 130px;
        left: 35px;
        width: 105px;
        height: 110px;
        background-image: url('../img/slide2.png');
        background-size: 100%;
        @include media-breakpoint-up(md) {
            top: 112px;
            left: 35px;
            height: 119px;
            width: 113px;
        }
    }
    .spacer {
        position: absolute;
        bottom: -15px;
        left: 20px;
        width: 259px;
        height: 3px;
        @include media-breakpoint-up(md) {
            bottom: 0;
            left: 43px;
        }
    }
}

.animation-scene.products {
    width: 290px;
    height: 240px;
    @include media-breakpoint-up(md) {
        width: 339px;
        height: 265px;
    }
    .product {
        position: absolute;
    }
    .product.elem1 {
        top: 18px;
        left: 79px;
        width: 125px;
        height: 130px;
        background-image: url('../img/slide1.png');
        background-position: top left;
        background-repeat: no-repeat;
        background-size: 100%;
        z-index: 9;
        border-radius: 18px 18px 0 0;
        @include media-breakpoint-up(md) {
            top: 0;
            left: 79px;
            width: 175px;
            height: 184px;
        }
    }
    .product.elem2 {
        top: 15px;
        left: 0;
        width: 125px;
        height: 130px;
        background-image: url('../img/slide2.png');
        background-position: top left;
        background-repeat: no-repeat;
        background-size: 100%;
        z-index: 3;
        transform: scale(.85);
        border-radius: 18px 18px 0 0;
        @include media-breakpoint-up(md) {
            top: 0;
            width: 175px;
            height: 184px;
        }
    }
    .product.elem3 {
        left: 165px;
        top: 15px;
        width: 125px;
        height: 130px;
        background-image: url('../img/slide3.png');
        background-position: top left;
        background-repeat: no-repeat;
        background-size: 100%;
        z-index: 6;
        transform: scale(.85);
        border-radius: 18px 18px 0 0;
        @include media-breakpoint-up(md) {
            top: 0;
            left: 165px;
            width: 175px;
            height: 184px;
        }
    }
    .sticker {
        position: absolute;
        bottom: 0;
        width: 62px;
        height: 62px;
    }
    .sticker.elem1 {
        left: 42px;
        background-image: url('../img/point1.png');
        background-position: top left;
        background-repeat: no-repeat;
        @include media-breakpoint-up(md) {
            left: 63px;
        }
    }
    .sticker.elem2 {
        left: 120px;
        background-image: url('../img/point2.png');
        background-position: top left;
        background-repeat: no-repeat;
        @include media-breakpoint-up(md) {
            left: 139px;
        }
    }
    .sticker.elem3 {
        left: 195px;
        background-image: url('../img/point3.png');
        background-position: top left;
        background-repeat: no-repeat;
        @include media-breakpoint-up(md) {
            left: 218px;
        }
    }
    .cursor {
        position: absolute;
        top: 230px;
        left: 230px;
        background-image: url('../img/cursor.png');
        background-position: top left;
        background-repeat: no-repeat;
        width: 50px;
        height: 50px;
        @include media-breakpoint-up(md) {
            left: 245px;
        }
    }
}

.animation-scene.seo {
    width: 290px;
    height: 280px;
    @include media-breakpoint-up(md) {
        width: 330px;
        height: 330px;
    }
    @include media-breakpoint-up(lg) {
        width: 360px;
    }
    .cloud {
        position: absolute;
    }
    .cloud.cloud1 {
        top: 22px;
        left: 0;
        width: 100px;
        height: 37px;
        background-image: url('../img/cloud1.png');
        background-position: top left;
        background-repeat: no-repeat;
        background-size: 100%;
        @include media-breakpoint-up(md) {
            width: 160px;
            height: 60px;
        }
        @include media-breakpoint-up(lg) {
            width: 222px;
            height: 83px;
        }
    }
    .cloud.cloud2 {
        top: 0;
        left: 130px;
        width: 67px;
        height: 32px;
        background-image: url('../img/cloud2.png');
        background-position: top left;
        background-repeat: no-repeat;
        background-size: 100%;
        @include media-breakpoint-up(md) {
            left: 170px;
        }
        @include media-breakpoint-up(lg) {
            left: 199px;
        }
    }
    .cloud.cloud3 {
        top: 30px;
        left: 205px;
        width: 86px;
        height: 35px;
        background-image: url('../img/cloud3.png');
        background-position: top left;
        background-repeat: no-repeat;
        background-size: 100%;
        @include media-breakpoint-up(md) {
            top: 40px;
            left: 205px;
            width: 120px;
            height: 50px;
        }
        @include media-breakpoint-up(lg) {
            left: 220px;
            width: 138px;
            height: 55px;
        }
    }
    .engine {
        position: absolute;
    }
    .engine .spacer {
        position: relative;
    }
    .engine .spacer .rocket {
        position: absolute;
        left: 0;
        width: 100%;
    }
    .engine .spacer .fire {
        position: absolute;
    }
    .engine.elem1 {
        top: 85px;
        left: 15px;
        width: 61px;
        height: 150px;
        @include media-breakpoint-up(md) {
            top: 92px;
            left: 8px;
            width: 85px;
            height: 202px;
        }
        @include media-breakpoint-up(lg) {
            left: 20px;
        }
    }
    .engine.elem1 .rocket {
        top: 0;
        width: 60px;
        height: 150px;
        background-image: url('../img/rocket1.png');
        background-position: top left;
        background-repeat: no-repeat;
        background-size: 100%;
        @include media-breakpoint-up(md) {
            width: 84px;
        }
    }
    .engine.elem1 .fire {
        width: 36px;
        height: 50px;
        top: 98px;
        left: 12px;
        background-image: url('../img/fire1.png');
        background-position: top left;
        background-repeat: no-repeat;
        background-size: 100%;
        @include media-breakpoint-up(md) {
            width: 50px;
            height: 70px;
            top: 132px;
            left: 17px;
        }
    }
    .engine.elem2 {
        width: 85px;
        height: 214px;
        top: 50px;
        left: 102px;
        @include media-breakpoint-up(md) {
            width: 117px;
            height: 287px;
            top: 44px;
            left: 112px;
        }
        @include media-breakpoint-up(lg) {
            left: 125px;
        }
    }
    .engine.elem2 .fire {
        width: 54px;
        height: 80px;
        top: 138px;
        left: 16px;
        background-image: url('../img/fire2.png');
        background-position: top left;
        background-repeat: no-repeat;
        background-size: 100%;
        @include media-breakpoint-up(md) {
            width: 71px;
            height: 101px;
            top: 186px;
            left: 23px;
        }
    }
    .engine.elem2 .rocket {
        width: 85px;
        height: 140px;
        background-image: url('../img/rocket2.png');
        background-position: top left;
        background-repeat: no-repeat;
        background-size: 100%;
        @include media-breakpoint-up(md) {
            width: 115px;
            height: 189px;
        }
    }
    .engine.elem3 {
        width: 75px;
        height: 180px;
        top: 90px;
        left: 210px;
        @include media-breakpoint-up(md) {
            top: 114px;
            left: 246px;
        }
        @include media-breakpoint-up(lg) {
            left: 260px;
        }
    }
    .engine.elem3 .fire {
        width: 44px;
        height: 62px;
        top: 116px;
        left: 16px;
        background-image: url('../img/fire3.png');
        background-position: top left;
        background-repeat: no-repeat;
        background-size: 100%;
    }
    .engine.elem3 .rocket {
        height: 119px;
        background-image: url('../img/rocket3.png');
        background-position: top left;
        background-repeat: no-repeat;
        background-size: 100%;
    }
}

.animation-scene.analytics {
    width: 290px;
    height: 220px;
    background-size: 100%;
    @include media-breakpoint-up(md) {
        width: 345px;
        height: 233px;
    }
    .analizator {
        position: absolute;
        top: 45px;
        right: 110px;
        width: 160px;
        height: 92px;
        background-image: url('../img/analizator.jpg');
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 100%;
        @include media-breakpoint-up(md) {
            top: 0;
            right: 149px;
            width: 196px;
            height: 114px;
        }
    }
    .paper {
        position: absolute;
        bottom: 0;
        right: 97px;
        width: 140px;
        height: 93px;
        background-image: url('../img/paper.jpg');
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 100%;
        @include media-breakpoint-up(md) {
            position: absolute;
            top: 105px;
            right: 134px;
            width: 187px;
            height: 117px;
        }
    }
    .upper_ana_part {
        position: absolute;
        top: 126px;
        right: 110px;
        width: 55px;
        height: 9px;
        background-image: url('../img/upper_part.jpg');
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 100%;
        @include media-breakpoint-up(md) {
            top: 105px;
            right: 149px;
            width: 76px;
            height: 9px;
        }
    }
    .bigbox {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 100px;
        height: 100px;
        background-image: url('../img/big-box.png');
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 100%;
        @include media-breakpoint-up(md) {
            top: 92px;
            width: 138px;
            height: 130px;
        }
    }
    .clock {
        position: absolute;
        width: 25px;
        height: 26px;
        top: 51px;
        background-image: url('../img/clock_arrow.png');
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 100%;
        @include media-breakpoint-up(md) {
            width: 30px;
            height: 30px;
            top: 10px;
        }
    }
    .clock.elem1 {
        left: 27px;
        transform: rotate(40deg);
        @include media-breakpoint-up(md) {
            left: 8px;
        }
    }
    .clock.elem2 {
        left: 55px;
        transform: rotate(0);
        @include media-breakpoint-up(md) {
            left: 43px;
        }
    }
    .clock.elem3 {
        left: 84px;
        transform: rotate(130deg);
        @include media-breakpoint-up(md) {
            left: 78px;
        }
    }
    .lamp {
        position: absolute;
        width: 8px;
        height: 8px;
        top: 83px;
        background-color: $analytics_lamp_bg;
        border-radius: 5px;
        opacity: .4;
        @include media-breakpoint-up(md) {
            width: 10px;
            height: 10px;
            top: 48px;
        }
    }
    .lamp.active {
        background-color: $analytics_lamp_active_bg;
    }
    .lamp.elem1 {
        left: 37px;
        @include media-breakpoint-up(md) {
            left: 20px;
        }
    }
    .lamp.elem2 {
        left: 64px;
        @include media-breakpoint-up(md) {
            left: 53px;
        }
    }
    .lamp.elem3 {
        left: 91px;
        @include media-breakpoint-up(md) {
            left: 86px;
        }
    }
    .abutton {
        position: absolute;
        top: 100px;
        width: 20px;
        height: 6px;
        background-image: url('../img/button.jpg');
        background-position: top left;
        background-repeat: no-repeat;
        background-size: 100%;
        cursor: pointer;
        @include media-breakpoint-up(md) {
            position: absolute;
            top: 68px;
            width: 26px;
            height: 9px;
        }
    }
    .abutton.active {
        background-position: bottom left;
    }
    .abutton.elem1 {
        left: 33px;
        @include media-breakpoint-up(md) {
            left: 14px;
        }
    }
    .abutton.elem2 {
        left: 59px;
        @include media-breakpoint-up(md) {
            left: 47px;
        }
    }
    .abutton.elem3 {
        left: 86px;
        @include media-breakpoint-up(md) {
            left: 80px;
        }
    }
    .radar {
        position: absolute;
        width: 30px;
        height: 30px;
        top: 49px;
        left: 143px;
        background-image: url('../img/radar.png');
        background-size: 100%;
        transform-origin: 0 32px;
        @include media-breakpoint-up(md) {
            width: 30px;
            height: 30px;
            top: 12px;
            left: 150px;
        }
    }
}