.vertical_tabs {
    display: flex;
    flex-direction: column;
    align-items: left;
    margin: 0;
    padding: 0;
    list-style: none;
    border: none;
    .vertical_tabs__item {
        display: flex;
        position: relative;
        margin: 0;
        margin-bottom: 10px;
    }
    .vertical_tabs__link {
        margin: 0;
        margin-left: 50px;
        padding: 5px;
        color: $brand-primary;
        border: none;
        &:hover,
        &:focus {
            color: $brand-third;
        }
    }
    .vertical_tabs__item a.active {
        font-weight: 600;
        font-size: 18px;
        color: $brand-third;
        border: none;
        cursor: default;
        pointer-events: none;
    }
    .icon {
        display: none;
        position: absolute;
        top: 50%;
        left: 0;
        width: 40px;
        height: 40px;
        fill: $brand-third;
        transform: translateY(-50%);
    }
    .active + .icon {
        display: block;
    }
}

.vertical_tabs--faq {
    .vertical_tabs__link {
        margin-left: 0;
    }
}