$filedset_border_color: #ced4da;
$questionnaire_bg: #0e76bc;

.questionnaire {
    margin-top: 20px;
    margin-bottom: 20px;
    @include media-breakpoint-up(md) {
        margin-top: 30px;
        margin-bottom: 40px;
    }
    select{
        cursor: pointer;
    }
    .custom-radio{
        label{
            cursor: pointer;
        }
    }
    &__text{
        font-size: 16px;
    }
    &__simple{
        #input_name{
            text-transform: capitalize;
        }
    }
    &__required{
        color: #e91f6d;
        font-weight: bold;
    }
    .questionnaire_type {
        text-align: center;
        .custom-radio:last-child {
            margin-right: 0;
        }
    }
    &__full {
        display: none;
    }
    .discount_system {
        border: 1px solid $filedset_border_color;
        border-radius: 4px;
        padding: 0 15px 5px 15px;
        margin-bottom: 15px;
        legend {
            width: inherit;
            padding: 0 8px;
            font-size: 18px;
        }
    }
    .discount_system_description,
    .standard_bonus_systems {
        display: none;
    }
    &__button {
        display: flex;
        margin: auto;
        color: $body-background;
        background-color: $questionnaire_bg;
        border-bottom: 1px solid darken($questionnaire_bg, 10%);
    }
    textarea {
        min-height: 80px;
    }
}