@import "../../../node_modules/slick-carousel/slick/slick.scss";

.slider {
    margin: 20px 0 0;
    padding: 20px 0;
    border-top: 1px solid $border-primary-color;
    border-bottom: 1px solid $border-primary-color;
    @include media-breakpoint-up(md) {
        margin: 40px 0 0;
    }
    &__caption {
        margin-top: 0;
        font-size: 30px;
    }
    .slider_content {
        position: relative;
        &__item {
            display: flex;
            flex-direction: column;
            @include media-breakpoint-up(md) {
                flex-direction: row;
            }
        }
        &__wrap:first-child {
            margin-bottom: 10px;
            padding-bottom: 10px;
            border-bottom: 1px solid $border-primary-color;
            @include media-breakpoint-up(md) {
                flex-basis: 25%;
                margin-bottom: 0;
                padding-bottom: 0;
                border-bottom: none;
            }
        }
        &__wrap:last-child {
            @include media-breakpoint-up(md) {
                flex-basis: 85%;
            }
        }
        .slick-dots {
            display: flex;
            position: absolute;
            top: -60px;
            right: 0;
            margin: 0;
            padding: 0;
            list-style: none;
            @include media-breakpoint-up(md) {
                left: 23%;
            }
            button {
                position: relative;
                width: 14px;
                height: 14px;
                margin-right: 5px;
                font-size: 0;
                line-height: 0;
                color: transparent;
                background: transparent;
                border: 0;
                outline: 0;
                cursor: pointer;
                &:after {
                    content: "";
                    position: absolute;
                    top: 1px;
                    left: 0;
                    width: 13px;
                    height: 13px;
                    background: $brand-third;
                    border-radius: 50%;
                    border: 2px solid $brand-third;
                    box-sizing: border-box;
                    transition: all .15s ease-out;
                }
            }
            .slick-active button {
                &:after {
                    background-color: $body-background;
                }
            }
        }
        &__person {
            margin-bottom: 0;
            font-weight: 500;
        }
        &__position {
            margin-bottom: 0;
            font-size: 14px;
            @include media-breakpoint-up(md) {
                margin-bottom: 15px;
                font-size: 16px;
            }
        }
        &__company {
            margin-bottom: 0;
            font-size: 14px;
            @include media-breakpoint-up(md) {
                font-size: 16px;
            }
        }
        &__company_link {
            font-weight: 500;
            color: $brand-third;
        }
        &__text {
            font-size: 16px;
            line-height: 1.3;
            @include media-breakpoint-up(md) {
                font-size: 21px;
            }
        }
    }
}