.feedback{
    margin-top: 40px;
    &__title{
        text-align: center;
    }
    &__text{
        text-align: center;
        font-size: 16px;
    }
    &__wrap{
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        background-color: #F0F8FF;
        border-radius: 5px;
        padding: 15px;
        flex-direction: column;
        flex-wrap: wrap;
        @include media-breakpoint-up(md){
            flex-direction: row;
        }
        &_link{
            font-size: 16px;
            font-weight: bold;
            color: #e91f6d;
            transition: 150ms;
            padding-bottom: 20px;
            @include media-breakpoint-up(md){
                padding-bottom: 0;
            }
            &:hover{
                color: #a31a51;
                transition: 150ms;
                img{
                    transform: scale(0.9);
                    transition: 150ms;
                }
            }
        }
        img {
            width: 30px;
            height: 30px;
        }
    }
}