.lang {
    display: none;
    z-index: 1;
    @include media-breakpoint-up(md) {
        display: block;
    }
    &--mob {
        display: block;
        padding-top: 5px;
    }
    &__now {
        display: block;
        min-width: 45px;
        padding: 2px 10px;
        text-align: center;
        color: $brand-primary;
        border: solid 2px $brand-third;
        cursor: pointer;
    }
    &__list {
        position: absolute;
        display: none;
        min-width: 45px;
        margin: 0;
        margin-top: -2px;
        padding: 2px 10px;
        list-style: none;
        text-align: center;
        background-color: $body-background;
        border: solid 2px $brand-third;
        border-top: none;
        z-index: 1;
        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 50%;
            width: 80%;
            height: 2px;
            background-color: $brand-third;
            transform: translateX(-50%);
        }
    }
    &__link {
        color: $brand-primary;
        &:hover,
        &:focus {
            color: $brand-third;
        }
    }
}