@font-face {
    font-family: 'Lato';
    src: url('../fonts/lato/lato-light-webfont.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'Lato';
    src: url('../fonts/lato/lato-regular-webfont.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'Lato';
    src: url('../fonts/lato/lato-bold-webfont.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'Lato';
    src: url('../fonts/lato/lato-heavy-webfont.woff2') format('woff2');
    font-weight: 600;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'Lato';
    src: url('../fonts/lato/lato-black-webfont.woff2') format('woff2');
    font-weight: 700;
    font-style: normal;
    font-display: block;
}