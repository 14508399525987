.button {
  padding: 7px 20px;
  width: 100%;
  max-width: 300px;
  height: 50px;
  color: #fff;
  background: linear-gradient(0deg, #af0848, #e91f6d);
  border: none;
  border-radius: 3px;
  cursor: pointer;
  outline: none;
  @include media-breakpoint-up(md){
    width: 250px;
    height: 60px;
  }
  @include media-breakpoint-up(lg){

  }
  &__text{
    font-size: 18px;
    margin: 0;
    font-weight: 500;
    @include media-breakpoint-up(md){
      font-size: 22px;
      font-weight: 500;
    }

  }
  &:hover {
    background: linear-gradient(0deg, darken(#af0848, 5%), #e91f6d);
  }
  &:active {
    background: linear-gradient(0deg, #af0848, #e91f6d);
  }
}
.banner_button{
  margin-right: auto;
  margin-left: auto;
  border-radius: 6px;
  margin-top: 50px;
  @include media-breakpoint-up(md){
    margin-top: 0;
  }
}
.header_button{
  margin-top: 35px;
  height: 45px;
}
.header_button--desk{
  height: 35px;
  padding: 5px 5px;
  .button__text{
    font-size: 15px;
  }
  @include media-breakpoint-up(md){
    display: block;
    width: 150px;
    height: 40px;
    .button__text{
      font-size: 16px;
      font-weight: 500;

    }
  }
}
.price_button{
  width: 100%;
  max-width: 250px;
  margin-left: auto;
  margin-right: auto;
@include media-breakpoint-up(md){
  width: 190px;
  height: 45px;
}
  .button__text{
    font-size: 18px;
  }
}
.consultation_button{
  margin: 25px auto;
  width: 100%;
  max-width: 300px;
  display: block;
  @include media-breakpoint-up(md){
    width: auto;
    max-width: unset;
  }
  border-radius: 6px;
  .button__text{
    font-size: 18px;
    margin: 0;
    font-weight: 500;
    @include media-breakpoint-up(md){
      font-size: 22px;
    }
  }
}