$subscription_form_button_color: #fff;
$subscription_form_button_bg1: #af0848;
$subscription_form_button_bg2: #e91f6d;

.subscription_form {
    position: relative;
    &__content {
        display: flex;
        flex-direction: column;
        @include media-breakpoint-up(md) {
            flex-direction: row;
        }
    }
    &__input {
        margin-bottom: 5px;
        padding: 7px 10px;
        color: $brand-primary;
        border: none;
        border-radius: 3px;
        @include media-breakpoint-up(md) {
            flex-basis: 250px;
            margin-bottom: 0;
            margin-right: 5px;
        }
    }
    &__button {
        padding: 7px 20px;
        color: $subscription_form_button_color;
        background: linear-gradient(0deg, $subscription_form_button_bg1, $subscription_form_button_bg2);
        border: none;
        border-radius: 3px;
        cursor: pointer;
        outline: none;
        &:hover {
            background: linear-gradient(0deg, darken($subscription_form_button_bg1, 5%), $subscription_form_button_bg2);
        }
        &:active {
            background: linear-gradient(0deg, $subscription_form_button_bg2, $subscription_form_button_bg1);
        }
    }
}
#subscriptionModal{
    .modal-header{
        border-bottom: none;
    }
}