$social_links_item--facebook_bg: #2377c8;
$social_links_item--facebook_shadow: #2b4b92;
$social_links_item--twitter_bg: #0bbcff;
$social_links_item--twitter_shadow: #2482bc;
$social_links_image_fill: #fff;

.social_links {
    display: flex;
    justify-content: center;
    @include media-breakpoint-up(md) {
        justify-content: flex-end;
    }
    &__item {
        width: 40px;
        height: 35px;
        margin-right: 10px;
        border-radius: 3px;
        &:last-child {
            margin-right: 0;
        }
        &--facebook {
            background-color: $social_links_item--facebook_bg;
            box-shadow: 0 2px 0 0 $social_links_item--facebook_shadow;
            &:hover {
                background-color: lighten($social_links_item--facebook_bg, 10%);
            }
        }
        &--twitter {
            background-color: $social_links_item--twitter_bg;
            box-shadow: 0 2px 0 0 $social_links_item--twitter_shadow;
            &:hover {
                background-color: lighten($social_links_item--twitter_bg, 10%);
            }
        }
    }
    &__link {
        display: block;
    }
    &__image {
        width: 40px;
        height: 30px;
        padding-top: 2px;
        fill: $social_links_image_fill;
    }
}