.float_on_scroll {
      top: 0;
      transition: top 300ms;
      left: 0;
      background-color: white;
      z-index: 5;
      &.show {
            position: fixed;
            top: 0;
            box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15);
      }
      &.out {
            top: -200px;
      }
}
