.page_wrapper {
    display: flex;
    flex-direction: column;
    height: 100vh;
    &__content {
        flex: 1 0 auto;
    }
    &__footer {
        flex-shrink: 0;
    }
}